import React, { useState, useEffect } from "react";

const ReviewDetailForm = (props) => {
  const initialValues = {
    menuSuggestionData: "",
    staffdata: "",
    isTabledata: "",
    diningdata: "",
    name: "",
    dateOfBirth: "",
    dateOfAnniversary: "",
    // address: "",
    mobileNo: "",
    noofpax: "",
    location: "",
    // companyName: "",
    email: "",
    comments: "",
  };
  const [data, setData] = useState(initialValues);
  useEffect(() => {
    props.otherDetail(data);
  }, [data]);
  useEffect(() => {
    setData(initialValues);
  }, [props.data]);
  return (
    <>
      {props.formType !== "GuestData" && (
        <>
          <div className="pb-4 z-10">
            <div className="text-lg font-medium pb-2">
              Did our staff make you feel special ?{" "}
            </div>
            <input
              type="text"
              value={data.staffdata}
              style={{
                color: `${
                  props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                }`,
              }}
              className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
              onChange={(e) => {
                setData({ ...data, staffdata: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col md:flex-row pb-4">
            <div className="text-lg font-medium  pr-8 pb-4 lg:pb-2">
              Did a manager visit your table ?{" "}
            </div>
            <fieldset className="flex flex-col md:flex-row">
              <div
                className="px-2"
                onClick={(e) => {
                  // console.log(e.target.value, "ss");
                  setData({ ...data, isTabledata: !data.isTabledata });
                }}
              >
                <input
                  type="radio"
                  value={data.isTabledata}
                  name="true"
                  checked={data.isTabledata === true}
                  className="mr-4"
                />
                <span className="font-bold  text-base">Yes</span>
              </div>
              <div
                className="px-2"
                onClick={(e) => {
                  // console.log(e.target.value, "ss");
                  setData({ ...data, isTabledata: !data.isTabledata });
                }}
              >
                <input
                  value={data.isTabledata}
                  checked={data.isTabledata === false}
                  type="radio"
                  className="mr-4"
                  // onChange={(e) => {
                  //   setData({ ...data, isTabledata: !data.isTabledata });
                  // }}
                />
                <span className="font-bold  text-base">No</span>
              </div>
            </fieldset>
          </div>
          <div className="pb-4">
            <div className="text-lg font-medium  pb-2">
              Any staff who made a difference to your dining experience ? Help
              us recognize talent
            </div>
            <textarea
              rows="1"
              value={data.diningdata}
              style={{
                color: `${
                  props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                }`,
              }}
              className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
              onChange={(e) => {
                setData({ ...data, diningdata: e.target.value });
              }}
            ></textarea>
          </div>
          <div className="pb-4">
            <div className="text-lg font-medium  pb-2">
              Do you think we should add something in the Menu.
            </div>
            <textarea
              rows="1"
              value={data.menuSuggestionData}
              style={{
                color: `${
                  props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                }`,
              }}
              className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
              onChange={(e) => {
                setData({ ...data, menuSuggestionData: e.target.value });
              }}
            ></textarea>
          </div>
        </>
      )}

      <div>
        {props && props.formType !== "GuestData" && (
          <div className="text-lg font-medium  pb-4 flex flex-col md:flex-row text-left">
            <div>
              We would like to know you better! ( Personal Information ){" "}
              <span className="text-red-500">*</span>
            </div>
          </div>
        )}
        {props && props.formType !== "GuestData" ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
              <div className="text-lg font-normal  pb-4">
                Name <span className="text-red-500">*</span>
                <span>
                  <input
                    name="name"
                    required={true}
                    type="text"
                    value={data.name}
                    style={{
                      color: `${
                        props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                      }`,
                    }}
                    className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                    }}
                  />
                </span>
              </div>
              <div className="text-lg font-medium ">
                Date of Birth <span className="text-red-500">*</span>
                <span>
                  <input
                    required
                    type="date"
                    value={data.dateOfBirth}
                    style={{
                      color: `${
                        props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                      }`,
                    }}
                    className="w-full uppercase border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                    onChange={(e) => {
                      setData({ ...data, dateOfBirth: e.target.value });
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
              <div className="text-lg font-normal ">
                Mobile <span className="text-red-500">*</span>
                <span>
                  <input
                    required
                    pattern="\d*"
                    type="text"
                    // minLength={10}
                    maxlength={10}
                    value={data.mobileNo}
                    style={{
                      color: `${
                        props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                      }`,
                    }}
                    className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                    onChange={(e) => {
                      setData({ ...data, mobileNo: e.target.value });
                    }}
                  />
                </span>
              </div>
              <div className="text-lg font-medium  pb-4">
                Email <span className="text-red-500">*</span>
                <span>
                  <input
                    required
                    type="email"
                    value={data.email}
                    style={{
                      color: `${
                        props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                      }`,
                    }}
                    className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                    onChange={(e) => {
                      setData({ ...data, email: e.target.value });
                    }}
                  />
                </span>
              </div>

              {/*  */}
            </div>
            <div className="text-lg font-medium ">
              Valuable Comments
              <span>
                <textarea
                  rows="2"
                  value={data.comments}
                  style={{
                    color: `${
                      props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                    }`,
                  }}
                  className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1"
                  onChange={(e) => {
                    setData({ ...data, comments: e.target.value });
                  }}
                />
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="text-lg font-normal  pb-4">
              Name <span className="text-red-500">*</span>
              <span>
                <input
                  name="name"
                  required
                  type="text"
                  value={data.name}
                  style={{
                    color: `${
                      props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                    }`,
                  }}
                  className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                />
              </span>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
              <div className="text-lg font-medium ">
                Date of Birth <span className="text-red-500">*</span>
                <span>
                  <input
                    required
                    type="date"
                    value={data.dateOfBirth}
                    style={{
                      color: `${
                        props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                      }`,
                    }}
                    className="w-full uppercase border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                    onChange={(e) => {
                      setData({ ...data, dateOfBirth: e.target.value });
                    }}
                  />
                </span>
              </div>
              {props.guestDataType !== "Holi" ? (
                <div className="text-lg font-medium ">
                  Date of Anniversary
                  <span>
                    <input
                      type="date"
                      value={data.dateOfAnniversary}
                      style={{
                        color: `${
                          props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                        }`,
                      }}
                      className="w-full border uppercase border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1"
                      onChange={(e) => {
                        setData({ ...data, dateOfAnniversary: e.target.value });
                      }}
                    />
                  </span>
                </div>
              ) : (
                <div className="text-lg font-normal  pb-4">
                  Location <span className="text-red-500">*</span>
                  <span>
                    <input
                      name="name"
                      required
                      type="text"
                      value={data.location}
                      style={{
                        color: `${
                          props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                        }`,
                      }}
                      className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                      onChange={(e) => {
                        setData({ ...data, location: e.target.value });
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
              <div className="text-lg font-normal ">
                Mobile <span className="text-red-500">*</span>
                <span>
                  <input
                    required
                    type="text"
                    pattern="\d*"
                    // minLength={10}
                    maxLength={10}
                    value={data.mobileNo}
                    style={{
                      color: `${
                        props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                      }`,
                    }}
                    className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                    onChange={(e) => {
                      setData({ ...data, mobileNo: e.target.value });
                    }}
                  />
                </span>
              </div>
              {props.guestDataType !== "Holi" && (
                <div className="text-lg font-medium  pb-4">
                  No. of PAX <span className="text-red-500">*</span>
                  <span>
                    <input
                      required
                      type="number"
                      rows="2"
                      value={data.noofpax}
                      style={{
                        color: `${
                          props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                        }`,
                      }}
                      className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                      onChange={(e) => {
                        setData({ ...data, noofpax: e.target.value });
                      }}
                    ></input>
                  </span>
                </div>
              )}
            </div>
          </>
        )}

        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4"> */}

        {/*  */}
        {/* </div> */}

        {/* {props.branch === "ametrine_24" && ( */}

        {/* )} */}
        {props.branch !== "ametrine_24" && (
          <>
            {/* <div className="text-lg font-medium  pb-4">
              Area of Residence <span className="text-red-500">*</span>
              <span>
                <textarea
                  required
                  rows="2"
                  value={data.address}
                  style={{
                    color: `${
                      props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                    }`,
                  }}
                  className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                  onChange={(e) => {
                    setData({ ...data, address: e.target.value });
                  }}
                ></textarea>
              </span>
            </div> */}
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4"> */}
            {/*  */}
            {/* <div className="text-lg font-medium ">
                Company Name <span className="text-red-500">*</span>
                <span>
                  <input
                    required
                    value={data.companyName}
                    type="text"
                    style={{
                      color: `${
                        props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                      }`,
                    }}
                    className="w-full border border-secondary-darkpurple-500 rounded p-1 px-2 focus:ring-1 "
                    onChange={(e) => {
                      setData({ ...data, companyName: e.target.value });
                    }}
                  />
                </span>
              </div> */}
            {/* </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default ReviewDetailForm;
