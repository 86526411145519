import React, { useState, useEffect } from "react";

const ReviewRatingForm = (props) => {
  // console.log(props.branch, "saurav");

  const data = [
    {
      key: 1,
      title: "Taste of Food",
      isOutstanding: false,
      isExcellent: false,
      isGood: false,
      isAverage: false,
    },
    {
      key: 2,
      title: "Quantity of Portion Served",
      isOutstanding: false,
      isExcellent: false,
      isGood: false,
      isAverage: false,
    },
    {
      key: 3,
      title: "Quality of Beverage",
      isOutstanding: false,
      isExcellent: false,
      isGood: false,
      isAverage: false,
    },
    {
      key: 4,
      title: "Quality of Service",
      isOutstanding: false,
      isExcellent: false,
      isGood: false,
      isAverage: false,
    },
    {
      key: 5,
      title: "Cleanliness",
      isOutstanding: false,
      isExcellent: false,
      isGood: false,
      isAverage: false,
    },
    {
      key: 6,
      title: "Ambience",
      isOutstanding: false,
      isExcellent: false,
      isGood: false,
      isAverage: false,
    },
  ];
  const [formdata, setFormData] = useState(data);
  //   console.log(formdata, "sad");

  useEffect(() => {
    props.ratingData(formdata);
  }, [formdata]);
  useEffect(() => {
    setFormData(data);
  }, [props.data]);

  return (
    <>
      <div className="pb-12 lg:block hidden z-10">
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-xl font-bold text-left p-2">
                How would you rate us?
              </th>
              <th
                className={`text-xl font-bold ${
                  props.branch === `ametrine_24`
                    ? `text-secondary-blue-1`
                    : `text-secondary-golden-500`
                }  p-2 italic`}
              >
                Outstanding
              </th>
              <th
                className={`text-xl font-bold ${
                  props.branch === "ametrine_24"
                    ? "text-secondary-blue-1"
                    : "text-secondary-golden-500"
                }  p-2 italic`}
              >
                Excellent
              </th>
              <th
                className={`text-xl font-bold ${
                  props.branch === "ametrine_24"
                    ? "text-secondary-blue-1"
                    : "text-secondary-golden-500"
                }  p-2 italic`}
              >
                Good
              </th>
              <th
                className={`text-xl font-bold ${
                  props.branch === "ametrine_24"
                    ? "text-secondary-blue-1"
                    : "text-secondary-golden-500"
                }  p-2 italic`}
              >
                Average
              </th>
            </tr>
          </thead>
          <tbody>
            {formdata.map((aa) => {
              return (
                <tr key={aa.key}>
                  <td className="font-semibold text-lg p-2">{aa.title} <span className="text-red-500">*</span></td>
                  <td className="text-center p-2">
                    <div className="checkmark">
                      <input
                      required
                        type="checkbox"
                        className="checkbox"
                        checked={aa.isOutstanding}
                        onChange={(e) => {
                          let tempData = [...formdata];
                          let tempIndex = formdata.findIndex(
                            (xx) => xx.key === aa.key
                          );
                          tempData[tempIndex].isOutstanding = e.target.checked;
                          tempData[tempIndex].isExcellent = false;
                          tempData[tempIndex].isGood = false;
                          tempData[tempIndex].isAverage = false;

                          setFormData(tempData);
                          //   console.log(tempData, "tempData");
                        }}
                      />
                      <span className="checkmark"></span>
                    </div>
                  </td>
                  <td className="text-center p-2">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={aa.isExcellent}
                      onChange={(e) => {
                        let tempData = [...formdata];
                        let tempIndex = formdata.findIndex(
                          (xx) => xx.key === aa.key
                        );
                        tempData[tempIndex].isExcellent = e.target.checked;
                        tempData[tempIndex].isOutstanding = false;
                        tempData[tempIndex].isGood = false;
                        tempData[tempIndex].isAverage = false;

                        setFormData(tempData);
                      }}
                    />
                  </td>
                  <td className="text-center p-2">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={aa.isGood}
                      onChange={(e) => {
                        let tempData = [...formdata];
                        let tempIndex = formdata.findIndex(
                          (xx) => xx.key === aa.key
                        );
                        tempData[tempIndex].isGood = e.target.checked;
                        tempData[tempIndex].isExcellent = false;
                        tempData[tempIndex].isOutstanding = false;
                        tempData[tempIndex].isAverage = false;

                        setFormData(tempData);
                      }}
                    />
                  </td>
                  <td className="text-center p-2">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={aa.isAverage}
                      onChange={(e) => {
                        let tempData = [...formdata];
                        let tempIndex = formdata.findIndex(
                          (xx) => xx.key === aa.key
                        );
                        tempData[tempIndex].isAverage = e.target.checked;
                        tempData[tempIndex].isExcellent = false;
                        tempData[tempIndex].isGood = false;
                        tempData[tempIndex].isOutstanding = false;
                        setFormData(tempData);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="lg:hidden block z-10">
        <div className="text-xl text-left text-bold  pb-4">
          How would you rate us?
        </div>
        {formdata.map((aa) => {
          return (
            <>
              <div key={aa.key} className="pb-4">
                <div
                  className={`text-lg font-medium ${
                    props.branch === "ametrine_24"
                      ? "text-secondary-blue-1"
                      : "text-secondary-golden-500"
                  }  p-2 italic`}
                >
                  {aa.title}
                </div>
                <div
                  className=" flex items-center p-2"
                  onClick={() => {
                    let tempData = [...formdata];
                    let tempIndex = formdata.findIndex(
                      (xx) => xx.key === aa.key
                    );
                    tempData[tempIndex].isOutstanding =
                      !tempData[tempIndex].isOutstanding;
                    tempData[tempIndex].isExcellent = false;
                    tempData[tempIndex].isGood = false;
                    tempData[tempIndex].isAverage = false;

                    setFormData(tempData);
                  }}
                >
                  <input
                    type="checkbox"
                    className=""
                    checked={aa.isOutstanding}
                  />
                  <div className="ml-4 text-bold">Outstanding</div>
                </div>
                <div
                  className="text-center flex items-center p-2"
                  onClick={() => {
                    let tempData = [...formdata];
                    let tempIndex = formdata.findIndex(
                      (xx) => xx.key === aa.key
                    );
                    tempData[tempIndex].isExcellent =
                      !tempData[tempIndex].isExcellent;
                    tempData[tempIndex].isOutstanding = false;
                    tempData[tempIndex].isGood = false;
                    tempData[tempIndex].isAverage = false;

                    setFormData(tempData);
                    //   console.log(tempData, "tempData");
                  }}
                >
                  <input type="checkbox" checked={aa.isExcellent} />
                  <div className="ml-4 text-bold">Excellent</div>
                </div>
                <div
                  className="text-center flex items-center p-2"
                  onClick={() => {
                    let tempData = [...formdata];
                    let tempIndex = formdata.findIndex(
                      (xx) => xx.key === aa.key
                    );
                    tempData[tempIndex].isGood = !tempData[tempIndex].isGood;
                    tempData[tempIndex].isExcellent = false;
                    tempData[tempIndex].isOutstanding = false;
                    tempData[tempIndex].isAverage = false;

                    setFormData(tempData);
                    //   console.log(tempData, "tempData");
                  }}
                >
                  <input type="checkbox" checked={aa.isGood} />
                  <div className="ml-4 text-bold ">Good</div>
                </div>
                <div
                  className="text-center flex items-center p-2"
                  onClick={() => {
                    let tempData = [...formdata];
                    let tempIndex = formdata.findIndex(
                      (xx) => xx.key === aa.key
                    );
                    tempData[tempIndex].isAverage =
                      !tempData[tempIndex].isAverage;
                    tempData[tempIndex].isExcellent = false;
                    tempData[tempIndex].isGood = false;
                    tempData[tempIndex].isOutstanding = false;

                    setFormData(tempData);
                    //   console.log(tempData, "tempData");
                  }}
                >
                  <input type="checkbox" checked={aa.isAverage} />
                  <div className="ml-4 text-bold ">Average</div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default ReviewRatingForm;