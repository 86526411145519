import React, { useState, useEffect } from "react";

const ReviewOtherDetailForm = (props) => {
  // console.log(props, "sad");
  // console.log(props, "sadddd");
  const ansDataInitaialValues = {
    isFriends: false,
    isMedia: false,
    isOther: false,
    isPassing: false,
    isSocialMedia: false,
  };
  const visitDataInitaialValues = {
    isSeveralTime: false,
    isWeek: false,
    isFortnight: false,
    isMonth: false,
    isEveryDay: false,
    isFirstTime: false,
  };
  const [ansData, setAnsData] = useState(ansDataInitaialValues);
  const [visitData, setVisitData] = useState(visitDataInitaialValues);

  useEffect(() => {
    props.detail(ansData);
  }, [ansData]);
  useEffect(() => {
    props.visitdata(visitData);
  }, [visitData]);

  useEffect(() => {
    setVisitData(visitDataInitaialValues);
    setAnsData(ansDataInitaialValues);
  }, [props.data]);

  return (
    <>
      <div className="pb-8 z-10">
        <div className=" text-lg font-medium pb-4">
          {`How did you find about ${
            (props.branch === "andheri" && "Amethhyyst") ||
            (props.branch === "ametrine_24" && "Ametrine24") ||
            (props.branch === "xci" && "Amethhyyst XCI")
          }?`}
          {/* <span className="text-red-500">*</span> */}
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1">
          <div
            className="p-2 flex items-center"
            onClick={() => {
              // console.log(e);
              setAnsData({
                isFriends: !ansData.isFriends,
                isMedia: false,
                isOther: false,
                isPassing: false,
                isSocialMedia: false,
              });
            }}
            onKeyDown={() => {
              // console.log(e);
              setAnsData({
                isFriends: !ansData.isFriends,
                isMedia: false,
                isOther: false,
                isPassing: false,
                isSocialMedia: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={ansData.isFriends}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Family & Friends</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setAnsData({
                isFriends: false,
                isMedia: !ansData.isMedia,
                isOther: false,
                isPassing: false,
                isSocialMedia: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={ansData.isMedia}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Media</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setAnsData({
                isFriends: false,
                isMedia: false,
                isOther: !ansData.isOther,
                isPassing: false,
                isSocialMedia: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={ansData.isOther}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Other</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setAnsData({
                isFriends: false,
                isMedia: false,
                isOther: false,
                isPassing: !ansData.isPassing,
                isSocialMedia: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={ansData.isPassing}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Passing By</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setAnsData({
                isFriends: false,
                isMedia: false,
                isOther: false,
                isPassing: false,
                isSocialMedia: !ansData.isSocialMedia,
              });
            }}
          >
            <input
              type="checkbox"
              checked={ansData.isSocialMedia}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Social Media</span>
          </div>
        </div>
      </div>

      {/* SecondQuestion */}
      <div className="pb-8">
        <div className=" text-lg font-medium pb-4">
          {`How often do you visit ${
            (props.branch === "andheri" && "Amethhyyst") ||
            (props.branch === "ametrine_24" && "Ametrine24") ||
            (props.branch === "xci" && "Amethhyyst XCI")
          }?`}
          {/* <span className="text-red-500">*</span> */}
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1">
          <div
            className="p-2 flex items-center"
            onClick={() => {
              // console.log(e);
              setVisitData({
                isSeveralTime: !visitData.isSeveralTime,
                isWeek: false,
                isFortnight: false,
                isMonth: false,
                isEveryDay: false,
                isFirstTime: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={visitData.isSeveralTime}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Several Times a Week</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setVisitData({
                isSeveralTime: false,
                isWeek: !visitData.isWeek,
                isFortnight: false,
                isMonth: false,
                isEveryDay: false,
                isFirstTime: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={visitData.isWeek}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Once a Week</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setVisitData({
                isSeveralTime: false,
                isWeek: false,
                isFortnight: !visitData.isFortnight,
                isMonth: false,
                isEveryDay: false,
                isFirstTime: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={visitData.isFortnight}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Once a Fortnight</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setVisitData({
                isSeveralTime: false,
                isWeek: false,
                isFortnight: false,
                isMonth: !visitData.isMonth,
                isEveryDay: false,
                isFirstTime: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={visitData.isMonth}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Once a Month</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setVisitData({
                isSeveralTime: false,
                isWeek: false,
                isFortnight: false,
                isMonth: false,
                isEveryDay: !visitData.isEveryDay,
                isFirstTime: false,
              });
            }}
          >
            <input
              type="checkbox"
              checked={visitData.isEveryDay}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">Everday</span>
          </div>
          <div
            className="p-2 flex items-center"
            onClick={() => {
              setVisitData({
                isSeveralTime: false,
                isWeek: false,
                isFortnight: false,
                isMonth: false,
                isEveryDay: false,
                isFirstTime: !visitData.isFirstTime,
              });
            }}
          >
            <input
              type="checkbox"
              checked={visitData.isFirstTime}
              className="mr-4 checkbox"
            />
            <span className="font-normal  text-base">First Time</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewOtherDetailForm;