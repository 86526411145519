import React from "react";
import Img from "../../assets/images/header_logo.png";
import Img1 from "../../assets/images/logo_title.png";
import Img2 from "../../assets/images/logo.png";
// import Img3 from "../../assets/images/xci_logo.png"
import Img4 from "../../assets/images/xci-logo-title.png";

const ReviewFormHeader = (props) => {
  // console.log(props, "props");
  return (
    <div className="py-8 z-10">
      {props.branch !== "ametrine_24" && (
        <div className="flex justify-center">
          <img src={Img} className="w-20 h-28" alt="icon" />
        </div>
      )}
      <div
        className={`flex justify-center ${
          props.branch === "ametrine_24" ? "pb-0" : "pb-6 mt-0"
        } `}
      >
        <img
          src={props.branch === "ametrine_24" ? Img2 : props.branch === "xci" ? Img4 : Img1}
          className={`${
            props.branch === "ametrine_24" ? "w-72 h-36" :props.branch === "xci" ? "w-40 h-16" : "w-36 h-12 "
          }`}
          alt="title-icon"
        />
      </div>
      <div
        className={`flex justify-center ${
          props.branch === "ametrine_24" ? "" : "mt-0"
        } `}
      >
        {props.formType !== "GuestData" ? (
          <div className="font-medium font-Niconne text-3xl text-center">
            <div className="">We value suggestions.</div>
            <div className="">Please help us serve you better!</div>
          </div>
        ) : (
          <div className="font-medium font-Niconne text-5xl text-center">
            Guest Data
          </div>
        )}
        {/*  */}
      </div>
    </div>
  );
};

export default ReviewFormHeader;
