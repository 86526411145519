import React, { useState } from "react";
import ReviewDetailForm from "../ReviewForm/ReviewDetailForm";
import ReviewFormHeader from "../ReviewForm/ReviewFormHeader";
import ReviewOtherDetailForm from "../ReviewForm/ReviewOtherDetailForm";
import ReviewRatingForm from "../ReviewForm/ReviewRatingForm";

import "../../assets/css/index.css";
// import axios from "axios";
// import swal from "sweetalert";
// import ThankYouPage from "./thank-you";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import moment from "moment";

const FeedbackForm = (props) => {
  const initialValues = {
    // branchData: null,
    ratingData: null,
    detailData: null,
    visitData: null,
    otherData: null,
  };

  const [reviewFormData, setReviewFormData] = useState(initialValues);

  const [isSave, setIsSave] = useState(false);

  const onFinish = () => {
    // if(data.Name === null || data.TelNo === null){

    // }
    let data = {
      branch: props.branch,
      Name: reviewFormData.otherData.name,
      DateofBirth: reviewFormData.otherData.dateOfBirth,
      // DateofAnniversary: reviewFormData.otherData.dateOfAnniversary,
      // NoOfPAX: reviewFormData.otherData.noofpax,
      MobileNo: reviewFormData.otherData.mobileNo,
      // CompanyName: reviewFormData.otherData.companyName,
      Email: reviewFormData.otherData.email,
      Visit: reviewFormData.visitData
        ? reviewFormData.visitData.isSeveralTime === true
          ? "Several Times a Week"
          : reviewFormData.visitData.isWeek === true
          ? "Once a Week"
          : reviewFormData.visitData.isFortnight === true
          ? "Once a Fortnight"
          : reviewFormData.visitData.isMonth === true
          ? "Once a Month"
          : reviewFormData.visitData.isEveryDay === true
          ? "Everday"
          : reviewFormData.visitData.isFirstTime === true
          ? "First Time"
          : null
        : null,
      ReferencedBy: reviewFormData.detailData
        ? reviewFormData.detailData.isFriends === true
          ? "Family & Friends"
          : reviewFormData.detailData.isMedia === true
          ? "Media"
          : reviewFormData.detailData.isOther === true
          ? "Other"
          : reviewFormData.detailData.isPassing === true
          ? "Passing By"
          : reviewFormData.detailData.isSocialMedia === true
          ? "Social Media"
          : null
        : null,
      AboutStaff: reviewFormData.otherData.staffdata,
      ManagerVisisted:
        reviewFormData.otherData.isTabledata === true ? "Yes" : "No",
      Experience: reviewFormData.otherData.diningdata,
      Suggestions_for_Menu: reviewFormData.otherData.menuSuggestionData,
      Comments: reviewFormData.otherData.comments,
      // Address: reviewFormData.otherData.address,
    };
    // console.log(data,"data");
    reviewFormData.ratingData &&
      reviewFormData.ratingData.forEach((oo) => {
        data = {
          ...data,
          [oo.title]: oo.isAverage
            ? "Average"
            : oo.isExcellent
            ? "Excellent"
            : oo.isGood
            ? "Good"
            : oo.isOutstanding
            ? "Outstanding"
            : "",
        };
      });
    // console.log(data, "data");

    let tempData = {
      Name: data.Name,
      Date_of_Birth: data.DateofBirth,
      // DateofAnniversary: data.DateofAnniversary,
      Mobile_No: data.MobileNo,
      // NoOfPAX: data.NoOfPAX,
      // CompanyName: data.CompanyName,
      Email_Id: data.Email,
      Taste_of_Food: data["Taste of Food"],
      Quantity_of_Portion_Served: data["Quantity of Portion Served"],
      Quality_of_Beverage: data["Quality of Beverage"],
      Quality_of_Service: data["Quality of Service"],
      Cleanliness: data.Cleanliness,
      Ambience: data.Ambience,
      Referenced_By: data.ReferencedBy,
      Visit: data.Visit,
      About_Staff: data.AboutStaff,
      Manager_Visisted: data.ManagerVisisted,
      Suggestions_for_Menu: data.Suggestions_for_Menu,
      Experience: data.Experience,
      Comments: data.Comments,
      // Address: data.Address,
      CreateDTTM: moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    console.log(tempData, "tempDta1");

    fetch(
      data.branch === "andheri"
        ? "https://script.google.com/macros/s/AKfycbwZh71Q0X69eiDnn7R40mlUGZ5DMkHEzMEBC2vowQ6YsehOd_xgCsZ3NOz9-X3EN3Xi/exec?action=andheri"
        : data.branch === "xci"
        ? "https://script.google.com/macros/s/AKfycbxRBAB4pBg9TnBH63xHOdubaJRuomc2u-hr-Jq9TcKrIqalsvMEcKkna5vIW87TCw/exec?action=xci"
        : "https://script.google.com/macros/s/AKfycbwP7os0obCWQ4bK1265FCjgcAj_drhvSukqGQ1phjhRpvsYZ70i2y8632KO3l4vKcut/exec?action=ametrine_24",
      // https://script.google.com/macros/s/AKfycbyAqS_tqE3JPfxkEKi_8UdxTIx2L6w5J-oANp3CPDtH770kJ6RfcG1C1WF7orMGXN-5/exec?action=andheri
      // https://script.google.com/macros/s/AKfycbx5Rs8HDjxHkn71GrRffmKLSMmhrwrq98J7705FsDedOsAa_Yjz0bZZ0btXCz0OkfvO/exec
      // `https://script.google.com/macros/s/AKfycbx1fFLeqWgVwZMgjaWw1fPkBwrxZmWJSBZNSWFaH0SeTF1XeBABQGsI1yDuM7OCpPFA/exec?action=${data.branch}`,
      {
        // header: {
        //   "Content-type": "application/json;charset=utf-8",
        //   // "Access-Control-Allow-Origin": true,
        //   // "Access-Control-Allow-Method": "POST",
        // },
        method: "POST",
        body: JSON.stringify(tempData),
        mode: "cors",
      }
    )
      .then((response) => {
        // console.log(response, "tempDta4");
        if (response.status === 200) {
          navigate(`/thank-you?form=${"feedback"}`);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    setReviewFormData(initialValues);
    setIsSave(!isSave);
  };

  return (
    <>
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        ></meta>
      </Helmet>
      <div
        className="z-10 md:h-full h-full"
        style={{
          backgroundColor: `${
            props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
          }`,
          color: `${props.branch === "ametrine_24" ? "#21659b" : "#ebb834"}`,
        }}
      >
        <div className="mx-auto max-w-6xl">
          <div className="md:px-14 px-6">
            <ReviewFormHeader branch={props.branch} formType={"Feedback"} />
            <ReviewRatingForm
              branch={props.branch}
              data={isSave}
              ratingData={(data) => {
                setReviewFormData({ ...reviewFormData, ratingData: data });
              }}
            />
            <ReviewOtherDetailForm
              branch={props.branch}
              data={isSave}
              detail={(data) => {
                setReviewFormData({ ...reviewFormData, detailData: data });
              }}
              visitdata={(data) => {
                setReviewFormData({ ...reviewFormData, visitData: data });
              }}
            />
            <ReviewDetailForm
              formType={"Feedback"}
              data={isSave}
              otherDetail={(data) => {
                setReviewFormData({ ...reviewFormData, otherData: data });
              }}
            />
          </div>
          <div className="md:px-14 px-6 block lg:flex justify-start w-full pb-5">
            <button
              style={{
                backgroundColor: `${
                  props.branch === "ametrine_24" ? "#21659b" : "#ebb834"
                }`,
                color: `${
                  props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                }`,
              }}
              className="rounded px-8 py-2 text-white font-semibold w-full outline-none focus:ring-1 ring-yellow-600"
              onClick={() => {
                onFinish();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackForm;
